import React from 'react';
import { useEnvironment, useTranslation, useFedopsLogger, useExperiments } from 'yoshi-flow-editor-runtime';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/http';
import { useStyles } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import { st, classes } from './List.st.css';
import { PlanList, MAX_ITEM_WIDTH } from './PlanList';
import { TabState, PopupEnum, MessageCode } from '../../../../types/common';
import { textAlignmentName, TextAlignmentName } from '../../../../utils/textAlignment';
import { messageToText, isMessageInformational } from '../../../../utils/errors';
import { Toast } from '../Toast';
import { useAdaptedSettings, useStylesParams } from '../../../../hooks';
import { EXPERIMENTS } from '../../../../constants';

import Popup from './Popup';

export interface ListProps {
  plans: PublicPlan[];
  tabState: TabState;
  popup: PopupEnum | null;
  interactionEnd: string;
  message?: MessageCode;
  dataHook?: string;
  selectPlan(plan: PublicPlan): void;
  hidePopup(): void;
  hideToast(): void;
  closeUpgradeModal(): void;
}

const List: React.FC<ListProps> = ({
  plans,
  tabState,
  selectPlan,
  popup,
  hidePopup,
  message,
  dataHook,
  hideToast,
  interactionEnd,
}) => {
  const { t } = useTranslation();
  const { isMobile, isEditorX } = useEnvironment();
  const fedops = useFedopsLogger();
  const stylesParams = useStylesParams();
  const settings = useAdaptedSettings();
  const styles = useStyles();
  const { experiments } = useExperiments();
  const areImprovedAppSettingsEnabled = experiments.enabled(EXPERIMENTS.IMPROVED_APP_SETTINGS);
  const showPageTitle = styles.get(stylesParams.showPageTitle);
  const showPageSubtitle = settings.get('showPageSubtitle');
  const showHeader = showPageTitle || showPageSubtitle;
  const showDisclaimer = styles.get(stylesParams.showDisclaimer);
  const titleAlignment = areImprovedAppSettingsEnabled
    ? textAlignmentName(styles.get(stylesParams.titleAlignment))
    : TextAlignmentName.CENTER;
  const subtitleAlignment = areImprovedAppSettingsEnabled
    ? textAlignmentName(styles.get(stylesParams.subtitleAlignment))
    : TextAlignmentName.CENTER;
  const disclaimerAlignment = areImprovedAppSettingsEnabled
    ? textAlignmentName(styles.get(stylesParams.disclaimerAlignment))
    : TextAlignmentName.CENTER;
  const textAlignment = textAlignmentName(styles.get(stylesParams.textAlignment));

  React.useEffect(() => {
    fedops.interactionEnded(interactionEnd);
  }, []);
  const visiblePlanCount = settings.countVisiblePlans(plans);
  const extraPlanListPadding = 30;
  const maxWidthStyle =
    !isEditorX && visiblePlanCount ? { maxWidth: MAX_ITEM_WIDTH * visiblePlanCount - extraPlanListPadding } : {};
  return (
    <div
      className={st(classes.root, { mobile: !!isMobile, textAlignment })}
      data-hook={dataHook ?? 'PackagePicker-wrapper'}
    >
      {plans.length ? (
        <>
          {showHeader && (
            <div className={classes.header} style={maxWidthStyle}>
              {showPageTitle && (
                <h1 className={st(classes.title, { textAlignment: titleAlignment })} data-hook="app-title">
                  {settings.get('pageHeaderText') || t('pp.defaults.pageTitle')}
                </h1>
              )}
              {areImprovedAppSettingsEnabled && showPageSubtitle && (
                <h2 className={st(classes.subtitle, { textAlignment: subtitleAlignment })} data-hook="app-subtitle">
                  {settings.get('pageSubtitleText') || t('pp.defaults.pageSubtitle')}
                </h2>
              )}
            </div>
          )}
          {message ? (
            <Toast onClose={hideToast} informational={isMessageInformational(message)}>
              {t(messageToText(message))}
            </Toast>
          ) : null}
          <PlanList selectPlan={selectPlan} plans={plans} showDemoHighlight={tabState === TabState.HIGHLIGHTED} />
          {showDisclaimer && (
            <div className={st(classes.disclaimer, { textAlignment: disclaimerAlignment })} style={maxWidthStyle}>
              <p>{settings.get('disclaimerText')}</p>
            </div>
          )}
        </>
      ) : (
        <h1 data-hook="empty-state-message" className={st(classes.empty)}>
          {t('pp.no-plans')}
        </h1>
      )}
      {popup ? <Popup popup={popup} hidePopup={hidePopup} /> : null}
    </div>
  );
};

export default List;
