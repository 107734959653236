import { NoBullet } from './NoBullet';
import { CircleTick } from './CircleTick';
import { HollowCircleTick } from './HollowCircleTick';
import { Tick } from './Tick';
import { Arrow } from './Arrow';

export interface IconProps {
  size: number;
}

export enum BenefitBulletType {
  NONE = 0,
  CIRCLE_TICK = 1,
  HOLLOW_CIRLCE_TICK = 2,
  TICK = 3,
  ARROW = 4,
}

export const iconsMap = {
  [BenefitBulletType.NONE]: NoBullet,
  [BenefitBulletType.CIRCLE_TICK]: CircleTick,
  [BenefitBulletType.HOLLOW_CIRLCE_TICK]: HollowCircleTick,
  [BenefitBulletType.TICK]: Tick,
  [BenefitBulletType.ARROW]: Arrow,
};
