import React, { useState, useCallback } from 'react';
import Collapsible from 'react-collapsible';
import { useStyles, useSettings } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import { useEnvironment, useExperiments } from 'yoshi-flow-editor-runtime';

import { st, classes } from './Footer.st.css';
import { ArrowDown } from './ArrowDown';
import { useStylesParams, useSettingsParams } from '../../../../../hooks';
import { EXPERIMENTS } from '../../../../../constants';
import { textAlignmentName } from '../../../../../utils/textAlignment';
import { iconsMap, BenefitBulletType } from '../../../commons/bulletIcons';

interface FooterProps {
  benefits: string[];
  highlighted: boolean;
  anyBenefits?: boolean;
}

export const Footer: React.FC<FooterProps> = ({ benefits, anyBenefits, highlighted }) => {
  const { isMobile } = useEnvironment();
  const stylesParams = useStylesParams();
  const styles = useStyles();
  const showPlanBenefits = styles.get(stylesParams.showBenefits);

  if (!showPlanBenefits) {
    return null;
  }
  if (isMobile) {
    return benefits.length > 0 ? <MobileBenefits benefits={benefits} highlighted={highlighted} /> : null;
  } else {
    return anyBenefits ? <Benefits benefits={benefits} mobile={false} highlighted={highlighted} /> : null;
  }
};

interface BenefitsProps {
  benefits: string[];
  highlighted: boolean;
  mobile: boolean;
}

const Benefits: React.FC<BenefitsProps> = React.memo(({ benefits, highlighted, mobile }) => {
  const { experiments } = useExperiments();
  const stylesParams = useStylesParams();
  const styles = useStyles();
  const { isRTL } = useEnvironment();
  const areImprovedAppSettingsEnabled = experiments.enabled(EXPERIMENTS.IMPROVED_APP_SETTINGS);
  const benefitsAlignment = areImprovedAppSettingsEnabled
    ? textAlignmentName(styles.get(stylesParams.benefitsAlignment))
    : textAlignmentName(styles.get(stylesParams.textAlignment));

  return (
    <ul
      data-hook="benefits"
      className={st(classes.benefits, {
        highlighted,
        mobile,
        textAlignment: benefitsAlignment,
        isRTL,
      })}
    >
      {benefits.map((benefit, i) => (
        <li data-hook="benefit" key={i}>
          {areImprovedAppSettingsEnabled ? <Benefit>{benefit}</Benefit> : benefit}
        </li>
      ))}
    </ul>
  );
});

const flex1 = { flex: 1 };

const MobileBenefits: React.FC<Omit<BenefitsProps, 'mobile'>> = React.memo(({ benefits, highlighted }) => {
  const [expanded, setExpanded] = useState<boolean>(highlighted);
  const toggleExpanded = useCallback(() => setExpanded(!expanded), [setExpanded, expanded]);

  return (
    <div onClick={toggleExpanded} style={flex1}>
      <Collapsible trigger="" open={expanded} easing="ease-out">
        <Benefits benefits={benefits} mobile highlighted={highlighted} />
      </Collapsible>
      <ArrowDown dataHook="expand-benefits" className={st(classes.arrow, { expanded, highlighted })} />
    </div>
  );
});

const Benefit: React.FC = ({ children }) => {
  const settings = useSettings();
  const styles = useStyles();
  const settingsParams = useSettingsParams();
  const stylesParams = useStylesParams();
  const { isRTL } = useEnvironment();

  const bulletType = settings.get(settingsParams.benefitsBullet);
  const hasIcon = bulletType !== BenefitBulletType.NONE;
  const IconComponent = iconsMap[bulletType];

  const iconProps = () => {
    const lineHeight = 1.5;
    const defaultBenfitFontSize = 14;
    const fontSize = styles.get(stylesParams.planBenefitsFont).size ?? defaultBenfitFontSize;
    return { size: fontSize * lineHeight, flipped: isRTL };
  };

  return (
    <>
      {hasIcon ? <IconComponent {...iconProps()} /> : null}
      {children}
    </>
  );
};
